import React from 'react';
import btcQRCode from './btc-qr-code.png';
import ethQRCode from './eth-qr-code.png';
import solQRCode from './sol-qr-code.png';

const DonationPage = () => {
  return (
    <div>
      <h1>Thank your for your support</h1>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <div>
          <h2>Bitcoin (BTC)</h2>
          <img src={btcQRCode} alt="BTC QR Code" style={{ width: '200px', height: '200px' }} />
        </div>
        <div>
          <h2>Ethereum (ETH)</h2>
          <img src={ethQRCode} alt="ETH QR Code" style={{ width: '200px', height: '200px' }} />
        </div>
        <div>
          <h2>Solana (SOL)</h2>
          <img src={solQRCode} alt="SOL QR Code" style={{ width: '200px', height: '200px' }} />
        </div>
      </div>
    </div>
  );
};

export default DonationPage;
