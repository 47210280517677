import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import LocationTable from './LocationTable';
import DonationPage from './DonationPage';

// 确保 locations 与后端的 cities 对象一致
const locations = [
  'Toronto', 
  'Vancouver', 
  'Montreal', 
  'Calgary', 
  'Ottawa', 
  'Halifax', 
  'Quebec City'  // 添加后端存在的城市
];

const fetchData = async () => {
  try {
    const response = await fetch(`/api/slots`);  // 替换为你的后端地址
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

const AvailabilityPage = () => {
  const [slotsData, setSlotsData] = useState({});

  useEffect(() => {
    const fetchSlotsData = async () => {
      const data = await fetchData();

      // 根据城市对数据进行分类
      const slotsByLocation = locations.reduce((acc, location) => {
        acc[location] = data.filter(slot => slot.city === location);
        return acc;
      }, {});

      setSlotsData(slotsByLocation);
    };

    fetchSlotsData();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Canada Visa Slot Availability
      </Typography>
      <DonationPage />
      <Grid container spacing={3}>
        {locations.map((location) => (
          <Grid item xs={12} md={6} key={location}>
            <LocationTable location={location} slots={slotsData[location] || []} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AvailabilityPage;
