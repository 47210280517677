import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

const LocationTable = ({ location, slots }) => {
  const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleString());

  useEffect(() => {
    // 每600秒刷新一次时间戳
    const interval = setInterval(() => {
      setLastUpdated(new Date().toLocaleString());
    }, 600000); 

    return () => clearInterval(interval); // 清除定时器
  }, []);

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" align="center" gutterBottom>
        {location} Availability
      </Typography>
      <Typography variant="subtitle2" align="center" gutterBottom>
        Last refreshed: {lastUpdated}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slots.slice(0, 10).map((slot, index) => (
            <TableRow key={index}>
              <TableCell>{slot.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LocationTable;
